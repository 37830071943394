<template>
  <form @submit.prevent="process()">
    <div class="m-b-30">
      <b-form-group :label="$t('group_form.group_title')+'*'">
        <b-form-input v-model="detailedSourceGroup.name" required></b-form-input>
        <div class="text-danger" v-if="!$v.detailedSourceGroup.name.maxLength">{{ $t("errors.name_max_length") }}</div>
      </b-form-group>
      <b-form-group  :label="$t('group_form.group_description')">
        <b-form-textarea placeholder="" v-model="detailedSourceGroup.description"></b-form-textarea>
        <div class="text-danger" v-if="!$v.detailedSourceGroup.description.maxLength">{{ $t("errors.name_max_length") }}</div>
      </b-form-group>
    </div>
    <h3>{{ $t('group_form.users') }}
      <a href="#" @click.prevent="selectUsers">
        <small>{{ typeof this.detailedSourceGroup.users_ids !== 'undefined' && detailedSourceGroup.users_ids.length ? $t('group_form.select_nothing'):$t('group_form.select_all') }}</small>
      </a>
    </h3>
    <div v-if="isLoading">
      <b-skeleton width="30%" :animated="true"></b-skeleton>

      <b-skeleton width="40%" :animated="true"></b-skeleton>

      <b-skeleton width="80%" :animated="true"></b-skeleton>

      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-else class="sources-columns users-columns m-b-30 border-checkbox-section">
      <div
          v-for="user in users"
          :key="user.id" class="border-checkbox-group border-checkbox-group-primary">
        <input type="checkbox" class="border-checkbox" :id="`user_${user.id}`" v-model="detailedSourceGroup.users_ids"
                    :value="user.id">
          <label :for="`user_${user.id}`" class="border-checkbox-label">
            {{ user.login }} ({{user.first_name}} {{user.last_name}})
            <router-link class="user-link"
                         :to="{name: 'UserDetail', params: {id: user.id}}">
              <i class="feather icon-info"></i>
            </router-link>
          </label>
      </div>
    </div>
    <h3>{{ $t('group_form.sources') }}
      <a 
        href="#" 
        @click.prevent="selectSources">
          <small>
            {{ typeof detailedSourceGroup.sources_ids !== 'undefined' && detailedSourceGroup.sources_ids.length ? $t('group_form.select_nothing'):$t('group_form.select_all') }}
          </small>
      </a>
      </h3>
      <b-form-group>
        <b-form-select :placeholder="$t('group_form.order')" v-model="order"> 
          <b-form-select-option v-for="(item, k) in sorting" :key="k" :value="item.value">{{item.item}}</b-form-select-option>
        </b-form-select>
      </b-form-group>
    <div v-if="isLoading">
      <b-skeleton width="30%" :animated="true"></b-skeleton>

      <b-skeleton width="40%" :animated="true"></b-skeleton>

      <b-skeleton width="80%" :animated="true"></b-skeleton>

      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-else class="sources-columns border-checkbox-section">
      <div
          v-for="source in sources"
          :key="source.id" class="border-checkbox-group border-checkbox-group-primary d-block">
        <input type="checkbox" class="border-checkbox" :id="`source_${source.id}`" v-model="detailedSourceGroup.sources_ids"
                    :value="source.id">
        <label :for="`source_${source.id}`" class="border-checkbox-label">{{ source.title }}</label>
      </div>
    </div>
    <br>
    <p class="text-danger" v-if="error.length">{{ $t('errors.' + error) }}</p>
    
    <b-button variant="primary" type="submit" class="btn"
      :class="{'btn-primary': $v.detailedSourceGroup.$invalid, 'btn-primary': !$v.detailedSourceGroup.$invalid }"
      :disabled="$v.detailedSourceGroup.$invalid || sending">
      {{ payloadProcess === 'create' ? $t('group_form.save_new') : $t('group_form.save') }} 
      <b-spinner small v-if="sending"></b-spinner></b-button>
    <router-link :to="{name: 'Groups'}" class="btn btn-secondary m-l-10">{{$t('modal.cancel')}}</router-link>
  </form>
</template>

<script>
import loadUserGroups from "@/mixins/loadUserGroups";
import {required, maxLength} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  name: "groupEditForm",
  mixins: [loadUserGroups],
  props: ['detailedSourceGroup', 'payloadProcess'],
  data() {
    return {
      isLoading: false,
      sending: false,
      order: "down_date",
      payload: {},
      error: ""
    };
  },

  computed: {
    sorting() {
      return [
        {item: `${ this.$t('group_form.order_by_date')} 0-9`, value: "up_date"},
        {item: `${ this.$t('group_form.order_by_date')} 9-0`, value: "down_date"},
        {item: `${ this.$t('group_form.order_by_name')} A-Z`, value: "up_name"},
        {item: `${ this.$t('group_form.order_by_name')} Z-A`, value: "down_name"},
      ];
    },
    ...mapGetters('auth', ['user'])
  },

  validations() {
    return {
      detailedSourceGroup: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        description: {
          maxLength: maxLength(255)
        }
      }
    }
  },
  methods:{
    process() {
      for(const key of Object.keys(this.detailedSourceGroup)) {
        if(this.detailedSourceGroup[key]) {
          this.payload[key] = this.detailedSourceGroup[key];
        }
      }
      switch (this.payloadProcess) {
        case 'create':
          this.create();
          break;
        case 'edit':
          this.edit();
          break;
      }
    },
    create() {
      this.sending = true;
      this.payload['private'] = this.user.roles.includes('superadmin');
      this.$store.dispatch('source_groups/createSourceGroup', this.payload).then(() => {
        this.$router.push({name: 'Groups'});
      }).catch(e => {
        const message = e.response.data.message;
        if(message === 'The name has already been taken.') {
          this.error = 'data_group_taken';
        } else {
          this.error = message;
        }
      }).finally(() => {
        this.sending = false;
      })
    },
    edit() {
      this.sending = true;
      this.$store.dispatch('source_groups/updateSourceGroup', this.payload).then(() => {
        this.$router.push({name: 'Groups'});
      }).finally(() => {
        this.sending = false;
      });
    },
    selectUsers() {
      if(typeof this.detailedSourceGroup.users_ids !== 'undefined' && this.detailedSourceGroup.users_ids.length) {
        this.detailedSourceGroup.users_ids = [];
      } else {
        this.detailedSourceGroup.users_ids = this.users.map(user => user.id);
      }
    },
    selectSources() {
      if(typeof this.detailedSourceGroup.sources_ids !== 'undefined' && this.detailedSourceGroup.sources_ids.length) {
        this.detailedSourceGroup.sources_ids = [];
      } else {
        this.detailedSourceGroup.sources_ids = this.sources.map(source => source.id);
      }
    }
  },
  watch: {
    order(value) {
      this.$store.dispatch('source_groups/changeOrderSorces', value);
    },
    '$i18n.locale': {
      handler() {
        this.isLoading = true
        // this.$store.dispatch('source_groups/getSources', {order_by: "released_at", order_type: "desc"})
        //     .then(() => {
        //       this.isLoading = false;
        //     })
      }
    }
  }
}
</script>

<style lang="scss">
h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}
h3 {
  font-weight: 700;
  margin-bottom: 10px;
}
.sources-columns {
   column-count: 2;
  @media (max-width: 992px) {
    column-count: 1;
  }
}
  .sources-columns {
    position: relative;

    .border-checkbox-section .border-checkbox-group .border-checkbox-label{
      margin-bottom: 10px;
      height: 60px;
    }
    .user-link {
      display: inline-block;
    }
    .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
      width: 100%;
      .check {
        margin-right: calc(0.75em - 1px);
      }
      .control-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0;
        position: relative;
        &:hover {
          background-color: #fff;
          overflow: visible;
          text-overflow: initial;
          text-decoration: underline;
          z-index: 99;
          padding-right: 20px;
        }
      }
    }
    @media (max-width: 992px) {
      &.users-columns {
        .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
          width: calc(100% - 35px);
        }
      }
    }
    .icon {
      svg {
        width: 16px;
      }
    }
  }
@media (min-width: 993px) {
  .border-checkbox-group.border-checkbox-group-primary {
    display: block;
  }
}

</style>